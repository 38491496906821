/* ------------------------------------------------------------
 * TODO
 * - Need a CSS reset
 * - ...
 * ----------------------------------------------------------- */

/* ------------------------------------------------------------
 * Vars
 * ----------------------------------------------------------- */
$container_width: 1200px;
$container_padding_x: 20px;

$color_light_blue: #DFEDF0;
$color_dark_blue: #192A41;

$hero_font_size: 64px;
$hero_leading: 80px;


@mixin above($width) {
  @media (min-width: $width + 1) {
    @content;
  }
}
@mixin below($width) {
  @media (max-width: $width - 1) {
    @content;
  }
}

/* ------------------------------------------------------------
 * General (TODO: May be replaced by Reset)
 * ----------------------------------------------------------- */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* ------------------------------------------------------------
 * Fonts
 * ------------------------------------------------------------ */
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-serif-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-serif-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-serif-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-serif-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-serif-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-serif-v21-latin-700.svg#NotoSerif') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v28-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* ------------------------------------------------------------
 * Container
 * ----------------------------------------------------------- */
html {
  height: 100%;
  background: $color_dark_blue;
}
body {
  font-family: 'Raleway';
}

/* ------------------------------------------------------------
 * Header
 * ----------------------------------------------------------- */
.header {

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100px;

  max-width: $container_width;
  margin: 0 auto;
  padding: 0 $container_padding_x;

  display: flex;
  justify-content: space-between;
  align-items: center;


}

.logo {

  a {
    display: block;
    text-indent: -1000em;
    width: 240px;
    height: 45px;
    background: transparent url(../img/logo-dark.svg) no-repeat 0 0;
    background-size: 100%;
  }

}

/* ------------------------------------------------------------
 * Nav
 * ----------------------------------------------------------- */


/* ------------------------------------------------------------
 * Hero
 * ----------------------------------------------------------- */
.hero {

  background: $color_light_blue;
  @include above(1500px) {
    background: $color_light_blue url(../img/background.png) no-repeat 50% 0;
    background-size: cover;
  }

  padding-bottom: 80px;
  padding-top: 130px;
  @include above(600px) {
    padding-bottom: 100px;
  }

  @include above(1000px) {
    padding-top: 200px;
  }

}
.hero__content {

  display: flex;
  flex-wrap: wrap;
  @include above(800px) {
    flex-wrap: nowrap;
  }

  justify-content: space-between;
  align-items: center;
  max-width: $container_width;
  padding: 0 $container_padding_x;
  margin: 0 auto;
}
.hero__image {

  order: 2;
  display: flex;
  justify-content: center;


  flex-basis: 100%;
  @include above(800px) {
    flex-basis: 50%;
  }

  img {
    display: block;
    max-width: 100%;
    @include above(800px) {
      max-width: 400px;
    }
    margin: 0 auto;
  }
}
.hero__text {
  order: 1;
  margin-bottom: 50px;

  h1 {
    max-width: 800px;
    font-family: 'Noto Serif';
    color: $color_dark_blue;
    margin-bottom: .5em;

    font-size: 32px;
    line-height: 40px;

    @include above(600px) {
      font-size: 48px;
      line-height: 64px;
    }

    @include above(1000px) {
      font-size: $hero_font_size;
      line-height: $hero_leading;
      letter-spacing: -1px;
    }

  }
  p {

    font-size: 18px;

    @include above(600px) {
      font-size: 21px;
    }

    @include above(1000px) {
      font-size: 24px;
    }
  }

}

/* ------------------------------------------------------------
 * Footer
 * ----------------------------------------------------------- */
.footer {
  color: white;

  max-width: $container_width;
  padding: 60px $container_padding_x;
  margin: 0 auto;

  h2 {
    font-family: 'Noto Serif';
    font-size: 32px;
    @include above(800px) {
      font-size: 36px;
    }
  }
  h3 {
    margin-top: 20px;
    border-top: 1px #ffffff55 solid;
    padding-top: 20px;
    font-family: 'Noto Serif';
  }
  p {
    margin-top: 1em;
  }
  p.sm {
    font-size: 12px;
  }
  a {
    color: white;
  }
}
